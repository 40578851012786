h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	color: #1d2025;
	margin: 0px;
}
.exam-section {
	height: 100vh;
}

.exam-timer {
	display: inline-block;
	padding: 0px 26px;
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	line-height: 40px;
	border-radius: 5px;
	background-color: #ffc600;
}

.exam-ques {
	font-size: 25px !important;
	line-height: 1.4;
	margin-bottom: 20px !important;
}

.exam-option {
	margin-bottom: 20px;
}
.exam-option input {
	zoom: 1.3;
	margin-top: 2px;
}
.exam-option label {
	cursor: pointer;
	-webkit-transition: all 0.4s linear;
	transition: all 0.4s linear;
}

.exam-option label:hover {
	color: #0075ff;
}

.jump-ques .jump-ques-ul .jump-btn {
	margin: 0 9px;
}

.jump-ques-ul {
	display: -webkit-box;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 0.25rem;
}

.jump-ques .jump-ques-ul .jump-btn button {
	font-size: 15px;
	color: #07294d;
	width: 40px;
	height: 40px;
	line-height: 36px;
	border: 2px solid #aaa;
	border-radius: 50%;
	text-align: center;
	-webkit-transition: all 0.4s linear;
	transition: all 0.4s linear;
	background: #fff;
}

.jump-ques .jump-ques-ul .jump-btn button.done {
	background-color: #ffc600;
	border-color: #ffc600;
	cursor: auto;
}

.jump-ques .jump-ques-ul .jump-btn button:hover {
	color: #000000 !important;
	background-color: #ffc600;
}
.exam-head {
	background-color: #fff;
	padding: 15px 30px;
	border-radius: 5px;
	overflow: hidden;
}
ul,
ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}
.exam-head .exam-head-div > ul > li {
	display: inline-block;
	margin-right: 60px;
}
.exam-head .exam-head-div > ul li:last-child {
	margin-right: 0;
}
.exam-head .exam-head-div ul li .time-icon span {
	color: #8a8a8a;
	font-size: 20px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}
.exam-ques-sec {
	overflow: hidden;
	border-radius: 5px;
}
.exam-ques-sec .exam-ques-div {
	padding: 40px 50px;
	background-color: #fff;
}
.exam-ques-sec .exam-ques-div h3 {
	font-size: 36px;
	color: #000;
	-webkit-transition: all 0.4s linear;
	transition: all 0.4s linear;
}
i,
span,
a {
	display: inline-block;
}
.exam-ques-sec .exam-ques-div ul {
	padding: 20px 0;
}
.courses-pagination .pagination .jump-btn {
	margin: 0 5px;
}
.exam-ques-sec .exam-ques-div ul li {
	display: inline-block;
	margin-right: 30px;
}
.exam-btn {
	font-size: 15px;
	color: #07294d;
	width: 40px;
	height: 40px;
	border: 2px solid #aaa;
	border-radius: 5px;
	text-align: center;
	-webkit-transition: all 0.4s linear;
	transition: all 0.4s linear;
	width: 100px !important;
	padding: 0.375rem 0.5rem !important;
}
.exam-btn:hover {
	background-color: #ffc600;
	border-color: #ffc600;
}
a:focus,
a:hover {
	text-decoration: none;
}
.jump-section .categories {
	background-color: #fff;
	padding: 30px 35px 25px;
	border-radius: 5px;
	height: 470px;
}
.jump-section .categories h4 {
	font-size: 24px;
	color: #07294d;
}
.jump-section .categories ul {
	padding-top: 15px;
}
.jump-section .categories ul li {
	line-height: 40px;
}

@media (min-width: 600px) {
	.display-pc-only {
		display: block !important;
	}
	#jump-sidebar-btn,
	.display-mob {
		display: none !important;
	}
}
@media (max-width: 600px) {
	.display-pc-only {
		display: none !important;
	}
	#jump-sidebar-btn,
	.display-mob {
		display: block !important;
	}
}

#jump-sidebar-section {
	left: 0;
	position: absolute;
	top: 210px;
	border: 1px solid #80808073;
	min-width: 250px;
	max-width: 500px;
	color: #000;
	transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
	transform-origin: bottom left;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	background-size: cover;
}
#jump-sidebar-section.active {
	margin-right: -430px;
	transform: rotateY(100deg);
}

#jump-sidebar-btn {
	padding: 20px;
	transition: all 0.3s;
	position: absolute;
	top: 102px;
	right: 15px;
}
.navbar-default {
	background-color: transparent !important;
	border-color: transparent !important;
}

#JumpSidebarCollapse {
	width: 40px;
	height: 40px;
	background: #f5f5f5;
	margin: 0;
}

#JumpSidebarCollapse span {
	width: 80%;
	height: 2px;
	margin: 0 auto;
	display: block;
	background: #555;
	transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
	transition-delay: 0.2s;
}

#JumpSidebarCollapse span:first-of-type {
	transform: rotate(45deg) translate(2px, 2px);
}
#JumpSidebarCollapse span:nth-of-type(2) {
	opacity: 0;
}
#JumpSidebarCollapse span:last-of-type {
	transform: rotate(-45deg) translate(1px, -1px);
}

#JumpSidebarCollapse.active span {
	transform: none;
	opacity: 1;
	margin: 5px auto;
}
.font-20 {
	font-size: 20px;
}
button:focus {
	outline: none !important;
}
.jump-bottom-sec {
	margin-top: 200px;
}
.jump-bottom-sec:before {
	content: '';
	position: absolute;
	width: 286px;
	height: 2px;
	background-color: #c1c1c1;
	bottom: 56px;
	left: 50px;
}
.color-theme {
	color: #ffc600;
}
