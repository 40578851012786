

.submit-head {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  overflow: hidden;
}

.submit-sec {
  border: 1px solid #bcbcbc;
  padding: 0 30px 18px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.submit-sec .submit-div {
  position: relative;
  padding-top: 20px;
}

.submit-sec .submit-div .icon {
  position: absolute;
  top: 32px;
  left: 0;
}

.submit-sec .submit-div .icon i {
  font-size: 60px;
  color: #ffc600;
}

.submit-sec .submit-div .cont {
  padding-left: 68px;
  padding-top: 9px;
}

.submit-sec .submit-div .cont h6 {
  font-size: 20px;
  color: #696969;
  margin-bottom: 0px;
  font-weight: 400;
}

.submit-sec .submit-div .cont span {
  font-size: 34px;
  color: #505050;
  font-weight: 600;
}