.exam-start-sec {
	height: 100vh;
}
.exam-start-sec .card {
	background: #fff;
	border-radius: 10px;
	padding: 50px 55px;
	margin: 0 50px;
}
.exam-start-sec .card img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}
.exam-start-sec .card p {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 30px;
}
.exam-start-sec .card .btn {
	width: 50%;
	margin: auto;
}
@media (min-width: 600px) {
	.exam-start-sec .card {
		width: 50%;
	}
}
